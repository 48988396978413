import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { Fragment, useCallback, useState } from 'react';

import { cn } from '@/lib/utils/generalUtils';
import { PlusIcon } from '@heroicons/react/solid';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ErrorMessage from './error-message';

const MultiSelect = ({
  options = [],
  name = '',
  label = '',
  placeholder = '',
  className = '',
  disabled = false,
  validation,
  isRequired,
  labelClassName,
}) => {
  const [query, setQuery] = useState('');

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const selectedValues = watch(name);

  const filteredOptions = useMemo(
    () =>
      query === ''
        ? options
        : options.filter((option) => {
            return option?.label?.toLowerCase().includes(query.toLowerCase());
          }),
    [options, query]
  );

  const removeItem = useCallback(
    (value) => {
      const removedSelection = selectedValues.filter(
        (selected) => selected !== value
      );
      setValue(name, removedSelection);
    },
    [setValue, name, selectedValues]
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      defaultValue={[]}
      render={({ field }) => (
        <Combobox
          value={field.value}
          onChange={(e) => field.onChange(e)}
          disabled={disabled}
          multiple
          nullable
        >
          <div className={cn(className, 'relative')}>
            <div className="space-y-1">
              <Combobox.Label className={cn('label', labelClassName)}>
                {label} {isRequired && <span className="text-red-500"> *</span>}
              </Combobox.Label>
              <Combobox.Button
                as="div"
                className={cn(
                  'relative',
                  disabled && 'disabled:opacity-80 opacity-80'
                )}
              >
                <Combobox.Input
                  onChange={(event) => setQuery(event.target.value)}
                  className="input !py-3"
                  autoComplete="off"
                  placeholder={placeholder}
                />

                <span className="flex items-center flex-shrink-0 pointer-events-none absolute top-1/2 -translate-y-1/2 right-0 pr-3 z-50">
                  <ChevronDownIcon
                    className={`w-5 h-5 text-com2`}
                    aria-hidden="true"
                  />
                </span>
              </Combobox.Button>

              {errors[name] && (
                <div className="error-msg">
                  <ErrorMessage message={errors?.root?.message} />
                </div>
              )}
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
              className={cn(
                'absolute z-50 w-full py-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
              )}
            >
              <Combobox.Options
                className={`absolute z-50 py-1 w-full mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
              >
                {filteredOptions.length === 0 && query !== '' && (
                  <div className="relative px-4 py-2 cursor-default select-none text-com2">
                    Nothing found.
                  </div>
                )}
                {filteredOptions.length > 0 &&
                  filteredOptions?.map((option, idx) => (
                    <Combobox.Option
                      key={idx}
                      value={option?.value}
                      as={Fragment}
                    >
                      {({ active, selected }) => (
                        <li
                          className={cn(
                            'relative cursor-default select-none py-2 pl-3 pr-4 font-medium flex items-center gap-x-2',
                            active
                              ? 'bg-neutral-100 text-primary'
                              : 'text-neutral-700',
                            selected && 'bg-neutral-100 text-primary'
                          )}
                        >
                          <span
                            className={cn(
                              selected ? 'font-medium' : 'font-normal',
                              'block pl-8'
                            )}
                          >
                            {option?.label}
                          </span>
                          {selected && (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3`}
                            >
                              <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </li>
                      )}
                    </Combobox.Option>
                  ))}
              </Combobox.Options>
            </Transition>

            <ul className="flex flex-wrap items-center gap-2 mt-2">
              {selectedValues?.map((option, idx) => {
                const selected = options?.find(
                  (opt) => opt.value === (option?.value || option)
                );

                return (
                  <Tag
                    key={idx}
                    text={selected?.label || selected?.name}
                    handleDelete={() => removeItem(option)}
                  />
                );
              })}
            </ul>
          </div>
        </Combobox>
      )}
    />
  );
};

export default MultiSelect;

function Tag({ text, handleDelete = () => {} }) {
  return (
    <div className="bg-neutral-100 text-primary p-2 flex items-center gap-x-2.5  rounded-3xl whitespace-nowrap">
      <span className="text-sm">{text}</span>
      <span onClick={handleDelete} className="cursor-pointer">
        <PlusIcon className="w-4 h-4 rotate-45 text-com2 hover:text-teal" />
      </span>
    </div>
  );
}
