import { cn } from '@/lib/utils/generalUtils';
import { useId } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';
import ErrorMessage from './error-message';

const Textarea = ({
  name,
  label,
  validation,
  className,
  children,
  isRequired,
  description,
  descriptionPosition = 'top',
  descriptionClassName,
  type,
}) => {
  const randomId = useId();
  const { formState, control } = useFormContext();

  const hasError = get(formState.errors, name);
  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      defaultValue=""
      render={({ field }) => (
        <div className="space-y-2">
          {!!label && (
            <label className="label !text-base" htmlFor={randomId}>
              {label} {isRequired && <span className="text-red-500"> *</span>}
            </label>
          )}

          {description && descriptionPosition === 'top' && (
            <p className={cn('text-sm text-neutral-700', descriptionClassName)}>
              {description}
            </p>
          )}

          <textarea
            id={randomId}
            min={type === 'number' ? 0 : undefined}
            className={cn('input', className)}
            {...field}
          />

          {description && descriptionPosition === 'bottom' && (
            <p className={cn('text-sm text-neutral-700', descriptionClassName)}>
              {description}
            </p>
          )}
          {children}
          {hasError && <ErrorMessage message={hasError?.message} />}
        </div>
      )}
    />
  );
};

export default Textarea;
