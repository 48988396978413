import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { Fragment, useId } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { cn } from '@/lib/utils/generalUtils';

const FormSelect = ({
  name,
  options,
  label,
  placeholder,
  multiple = false,
  isRequired,
  description,
  descriptionPosition = 'top',
  descriptionClassName,
  validation,
}) => {
  const { control } = useFormContext();

  const randomId = useId();

  const getLabel = (value) => {
    if (!value) return '';

    const option = options.find((opt) => opt.value === value);

    return option?.label || '';
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      defaultValue={[]}
      render={({ field }) => (
        <Listbox
          value={field.value}
          onChange={(value) => field.onChange(value)}
        >
          <div className="relative space-y-2">
            {!!label && (
              <label className="label" htmlFor={randomId}>
                {label} {isRequired && <span className="text-red-500"> *</span>}
              </label>
            )}

            {description && descriptionPosition === 'top' && (
              <p
                className={cn('text-sm text-neutral-700', descriptionClassName)}
              >
                {description}
              </p>
            )}

            <div>
              <Listbox.Button className="relative flex h-11 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-3 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                <span className={cn('block truncate')}>
                  {getLabel(field.value) || placeholder}
                </span>
                <span className="pointer-events-none absolute top-1/2 -translate-y-1/2 right-0 flex items-center pr-3">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              {description && descriptionPosition === 'bottom' && (
                <p
                  className={cn(
                    'text-sm text-neutral-700',
                    descriptionClassName
                  )}
                >
                  {description}
                </p>
              )}

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 max-h-60 w-full z-50 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                  {options.map((option, optionIdx) => (
                    <Listbox.Option
                      key={optionIdx}
                      className={({ active, selected }) =>
                        cn(
                          'relative cursor-default select-none py-2 pl-10 pr-4',
                          active
                            ? 'bg-neutral-100 text-primary'
                            : 'text-neutral-700',
                          selected && 'bg-neutral-100 text-primary'
                        )
                      }
                      value={option.value}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {option.label}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        </Listbox>
      )}
    />
  );
};

export default FormSelect;
