import { cn } from '@/lib/utils/generalUtils';
import { useId } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { RadioGroup, RadioGroupItem } from '../ui/radio-group';

const RadioCard = ({
  name,
  label,
  labelClassName,
  validation,
  options,
  createURL,
}) => {
  const { control } = useFormContext();

  const randomId = useId();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={false}
      rules={validation}
      render={({ field }) => (
        <>
          {label && (
            <label className={cn('label', labelClassName)} htmlFor={randomId}>
              {label}
            </label>
          )}
          <RadioGroup
            onValueChange={(value) => {
              field.onChange(value);
              if (!!createURL) {
                createURL(value);
              }
            }}
            defaultValue={field.value}
            className="gap-6"
          >
            {options?.map((option, idx) => {
              const id = `${option.value}-${idx}`;
              const isSelected = option.value === field.value;

              return (
                <div key={id}>
                  <RadioGroupItem
                    value={option.value}
                    id={id}
                    className="hidden"
                  />
                  <label htmlFor={id} className="font-normal">
                    <div
                      className={cn(
                        'flex items-center gap-6 border border-neutral-300 p-4 rounded-md transition-all ease-out duration-200 group hover:border-primary-600',
                        isSelected && 'border-primary-600'
                      )}
                    >
                      <div className="flex-1">
                        <h3 className="text-base md:text-lg font-medium">
                          {option.label}
                        </h3>
                        <p className="text-sm md:text-base">
                          {option.description}
                        </p>
                      </div>

                      {option.icon && (
                        <div
                          className={cn(
                            'rounded-md p-4 w-[60px] h-[60px] lg:h-[80px] lg:w-[80px] grid place-content-center bg-neutral-100 group-hover:bg-primary-600',
                            isSelected && 'bg-primary-600'
                          )}
                        >
                          <option.icon
                            className={cn(
                              'w-6 h-6  group-hover:text-white',
                              isSelected ? 'text-white' : 'text-neutral-800'
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </label>
                </div>
              );
            })}
          </RadioGroup>
        </>
      )}
    />
  );
};

export default RadioCard;
