import { cn } from '@/lib/utils/generalUtils';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline';
import { PlusIcon } from '@heroicons/react/solid';
import LoadingSpinner from '../ui/loading-spinner';

const FormButton = ({
  loading,
  disabled,
  action,
  isFinal = false,
  finalText = 'Submit',
  className,
  Icon = PlusIcon,
  onClick,
}) => {
  return (
    <button
      disabled={loading || disabled}
      aria-disabled={loading || disabled}
      className={cn(
        'button large focus:!ring-0 focus:!ring-offset-0',
        action === 'next'
          ? 'max-w-[147px] w-full'
          : '!p-0 !bg-transparent !shadow-none !border-none !text-neutral-700 border border-primary-100',
        className
      )}
      onClick={onClick}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {action === 'next' ? (
            <>
              {isFinal ? (
                <>
                  <Icon className="icon-sm mr-2" /> {finalText}
                </>
              ) : (
                <>
                  Next <ArrowRightIcon className="icon-sm ml-2" />
                </>
              )}
            </>
          ) : (
            <>
              <ArrowLeftIcon className="icon-sm mr-2" /> Back
            </>
          )}
        </>
      )}
    </button>
  );
};

export default FormButton;
