import { cn } from '@/lib/utils/generalUtils';

const ErrorMessage = ({ message, className, children }) => {
  return (
    <div className={cn('mt-1 flex items-center gap-x-2', className)}>
      {/* <ExclamationCircleIcon className="h-5 w-5 text-red-500" /> */}
      <span className="text-red-500 text-sm">{message}</span>

      {children}
    </div>
  );
};

export default ErrorMessage;
